import { ref, readonly } from 'vue';

export function useAuth() {

    const token = ref(localStorage.getItem('authToken') || null);
    const user = ref(JSON.parse(localStorage.getItem('user')) || null);
    const cfr = ref(localStorage.getItem('cfr') || null);
    const regionIso = ref(localStorage.getItem('regionIso') || null);

    function authenticateUser(authToken, userData, userCfr, userRegionIso) {
        token.value = authToken;
        user.value = userData;
        cfr.value = userCfr;
        regionIso.value = userRegionIso;

        localStorage.setItem('authToken', authToken);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('cfr', String(userCfr));
        localStorage.setItem('regionIso', String(userRegionIso));
    }

    function logout() {
        token.value = null;
        user.value = null;
        cfr.value = null;
        regionIso.value = null;
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        localStorage.removeItem('cfr');
        localStorage.removeItem('regionIso');
        localStorage.removeItem('currentFilters');

        window.location.href = '/';
    }

    const isAuthenticated = () => {
        return !!token.value;
    };

    return {
        isAuthenticated: readonly(isAuthenticated),
        user: readonly(user),
        cfr: readonly(cfr),
        regionIso: readonly(regionIso),
        authenticateUser,
        logout,
    };
}
