<!-- components/UserHeader.vue -->
<template>
    <v-sheet class="pa-2 mb-6 page-background ma-4" :elevation="0">
        <v-row align="center" justify="start">
            <v-col class="d-flex" cols="6">
                <div>
                    <a href="/">
                        <v-img :src="require('@/assets/img/logo.png')" height="35" width="100"></v-img>
                    </a>
                </div>
                <v-btn v-if="isHomePage" rounded href="/" class="pa-0 ma-0 header-btn" variant="text" color="info">
                    Личный кабинет <br> Статистика сообщений
                </v-btn>
            </v-col>

            <v-col class="d-flex display-1 justify-end">
                <template v-if="isAuthenticated()">
                    <span class="header-btn__account">{{ user.name }}</span>
                    <v-btn color="info" class="ml-2 header-btn" @click="logout" prepend-icon="mdi mdi-logout" rounded
                        variant="text" stacked height="40">
                        Выйти
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn color="info" to="/authorization" class="header-btn"
                        prepend-icon="mdi mdi-account-circle-outline" rounded variant="text" stacked height="40">
                        Вход
                    </v-btn>
                </template>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { useAuth } from '@/composables/useAuth';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
    setup() {
        const { isAuthenticated, user, logout } = useAuth();
        const route = useRoute();

        const isHomePage = computed(() => route.path === '/');
        
        return {
            isAuthenticated,
            user,
            logout,
            isHomePage,
        };
    },
};
</script>

<style scoped>
.header-btn .v-btn__content {
    font-size: clamp(4px, 2vw, 15px);
}
.header-btn__account {
    align-content: center;
    text-align: center;
    font-weight: 600;
    color: #2196f3;
    border-bottom: 2px solid #2196f3;
    margin-right: 20px;
    border-top: 2px solid #2196f3;
    padding: 5px 20px;
}


</style>