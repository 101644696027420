<template>
  <div>
    <div class="text-center" v-if="isLoading"><v-progress-circular color="info" indeterminate></v-progress-circular>
      <br>
      Загрузка сообщений, пожалуйста подождите.
    </div>
    <div v-for="message in messages" :key="message.messageNumber" class="my-5">
      <v-card class="mb-4 pa-3" :elevation="3">
        <v-row>
          <v-col cols="6">
            <v-card-title class="mb-5">
              <v-row>
                <span class="grey--text ml-5 text-primary text-caption font-weight-bold">
                  #{{ message.messageNumber }}
                </span>
                <span class="grey--text ml-5 text-grey text-caption">
                  {{ message.createdAt }}
                </span>
              </v-row>
            </v-card-title>
            <v-card-subtitle class="pb-1 grey--text">
              <v-chip class="rounded-0" :color="getStatusColor(message.status)">
                {{ getStatusText(message.status) }}
              </v-chip>
            </v-card-subtitle>
            <v-card-text>
              <div class="mb-2 font-weight-bold text-caption">
                Номер УИК: <span class="text-primary">{{ message.numberUIK }}</span>
              </div>
              <div class="mb-2 font-weight-bold text-caption">
                <span class="text-primary">{{ message.region }}</span>
              </div>
              <div class="mb-2 font-weight-bold text-caption">
                Избирательная кампания: <span class="text-primary"> Единый День Голосования 2024</span>
              </div>
            </v-card-text>
          </v-col>
          <v-col cols="6" class="d-flex flex-column">
            <v-card-text>
              <div class="mb-2 text-caption text-grey mt-16">{{ message.message }}</div>
            </v-card-text>
            <div class="images-container" v-if="message.files && message.files.length">
              <img v-for="(file, index) in message.files" :key="index" @click="showImage(file)" :src="file"
                class="message-image" width="100" />
            </div>

            <v-card-actions class="justify-end">
              <v-btn color="primary" @click="goToCustomerMessage(message.messageNumber)" rounded="xl"
                variant="outlined">
                Подробнее
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-pagination v-model="currentPage" :length="Math.ceil(totalMessages / pageSize)"
      @update:modelValue="onPageChange"></v-pagination>
  </div>
  <v-dialog v-model="dialog" width="400">
    <v-img :src="selectedImage" @click.self="dialog = false"></v-img>
  </v-dialog>
</template>

<script setup>
import { defineExpose, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStatus } from '@/composables/useStatus';
import { useAuth } from '@/composables/useAuth';

const route = useRoute();
const router = useRouter();
const { getStatusText, getStatusColor } = useStatus();
const { isAuthenticated } = useAuth();

const messages = ref([]);
const totalMessages = ref(0);
const currentPage = ref(1);
const pageSize = ref(30);
const currentStatus = ref(null);
const isPageChangeTriggered = ref(false);
const isLoading = ref(false);
const filters = ref({});


const fetchMessages = async (status = null, messageFilters = {}, page = null) => {
  isLoading.value = true;
  messages.value = [];

  if (page) {
    currentPage.value = page;
  }

  const params = new URLSearchParams({
    page: currentPage.value,
    size: pageSize.value,
  });

  if (status !== null) {
    params.append('status', status);
    currentStatus.value = status;

    if (!messageFilters || typeof messageFilters !== 'object') {
      messageFilters = {};
    }
    messageFilters.useFilter = true
    messageFilters.status = {
      [currentStatus.value]: true,
    };
  }

  Object.assign(filters.value, messageFilters);

  // Проверяем, есть ли фильтры
  const hasFilters = Object.keys(filters.value).length > 0;

  const requestBody = {
    ...filters.value,
  };

  try {
    let url = `${process.env.VUE_APP_BACKEND_API_BASE_URL}/api/messages?${params.toString()}`;

    if (isAuthenticated()) {
      url = hasFilters ? `${process.env.VUE_APP_BACKEND_API_BASE_URL}/api/filter/messages?${params.toString()}` : url;
    } else {
      url = `${process.env.VUE_APP_BACKEND_API_BASE_URL}/messages?${params.toString()}`;
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();
    messages.value = data.messages;
    totalMessages.value = data.total;

    router.push({
      query: {
        page: currentPage.value,
        status: currentStatus.value || ''
      }
    });
  } catch (error) {
    console.error('Ошибка получения данных:', error);
  } finally {
    isLoading.value = false;
  }
};

const goToCustomerMessage = (messageNumber) => {
  router.push({ name: 'CustomerMessage', params: { messageID: messageNumber } });
};

const onPageChange = (newPage) => {
  isPageChangeTriggered.value = true;
  fetchMessages(currentStatus.value, filters.value, newPage);
};

// Объединяем функции onMounted
onMounted(() => {
  const savedFilters = localStorage.getItem('currentFilters');
  if (savedFilters) {
    filters.value = JSON.parse(savedFilters);
  }

  const pageFromQuery = route.query.page ?? 1;
  const statusFromQuery = route.query.status;

  if (pageFromQuery && !isNaN(pageFromQuery)) {
    currentPage.value = parseInt(pageFromQuery, 10);
  }
  currentStatus.value = statusFromQuery || null;

  // fetchMessages(currentStatus.value, filters.value);
});

watch(
    () => route.query.page,
    (newPage) => {
      const savedFilters = localStorage.getItem('currentFilters');
      if (savedFilters) {
        filters.value = JSON.parse(savedFilters);
      }


      if (newPage && !isNaN(newPage)) {
        currentPage.value = parseInt(newPage, 10);

        fetchMessages(currentStatus.value, filters.value);
      }
    },
    { immediate: true }
);


defineExpose({
  fetchMessages
});
const dialog = ref(false);
const selectedImage = ref('');

const showImage = (imageUrl) => {
  selectedImage.value = imageUrl;
  dialog.value = true;
};
</script>
