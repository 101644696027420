<template>
  <div>
     <UserHeader />

  <v-container class="text-message__all" fluid>
    <v-row>
      <v-col cols="12">
        <h5 class="font-weight-bold">Отправить сообщение</h5>
        <p class="text-blue font-weight-light mt-4">
          Поля, помеченные <span class="text-red">*</span>, обязательны для заполнения
        </p>
      </v-col>
    </v-row>

    <v-form ref="form" v-model="formValid" @submit.prevent="submitForm">
      <v-row class="mt-10">
        <v-col cols="12" md="4">
          <h4 class="font-weight-bold mb-4">Персональные данные</h4>

          <v-text-field
              v-model="formData.name"
              label=""
              color="blue"
              class="mt-1"
              outlined
              required
              :rules="[v => !!v || 'Поле обязательно']">
            <template v-slot:label>
              <span>ФИО
                <span v-if="!formData.name" class="text-red" style="margin-left: 4px;">
                  *
                </span>
              </span>
            </template>
          </v-text-field>

          <v-checkbox
              v-model="formData.showName"
              label="Разрешаю отображать мои ФИО на сайте"
              color="info">
          </v-checkbox>

          <v-text-field
              v-model="formData.email"
              label="Email"
              color="blue"
              class="mt-1"
              placeholder="your@mail.ru"
              outlined
              required
              :rules="[v => !!v || 'Поле обязательно', v => emailPattern.test(v) || 'Некорректный email']">
            <template v-slot:label>
      <span>Email
        <!-- Проверяем валидность email -->
        <span v-if="!isEmailValid" class="text-red" style="margin-left: 4px;">
          *
        </span>
      </span>
            </template>
          </v-text-field>



          <v-text-field
              v-model="formData.otherContact"
              label="Прочие контактные данные"
              color="blue"
              class="mt-1"
              placeholder="Не публикуется без Вашего согласия"
              outlined>
          </v-text-field>

          <v-checkbox
              v-model="formData.agreeWithTerms"
              color="info"
              :rules="[v => !!v || 'Необходимо согласие на обработку персональных данных']"
          >
          <template v-slot:label>
            <span>Даю согласие <a href="/customAgreement" target="_blank" class="custom-label">на обработку моих персональных данных</a>
              <span v-if="!formData.agreeWithTerms" class="text-red" style="margin-left: 4px;">
                *
              </span>
            </span>
          </template>
          </v-checkbox>
        </v-col>

        <!-- Данные об избирательном участке -->
        <v-col cols="12" md="4">
          <h4 class="font-weight-bold mb-4">Данные об избирательном участке</h4>

          <v-select
              v-model="formData.regionId"
              :items="regions"
              item-text="title"
              item-value="value"
              label="Регион"
              placeholder="Выберите регион"
              outlined
              clearable
              filterable
              color="blue"
              required
              :loading="loading"
              :rules="[v => !!v || 'Выберите регион']"
              @focus="loadRegions"
          >
            <template v-slot:label>
              <span>Регион
                <span v-if="!formData.regionId" class="text-red" style="margin-left: 4px;">
                  *
                </span>
              </span>
            </template>
            <template v-slot:no-data>
              <p style="padding-left: 5px;">Загрузка регионов...</p>
            </template>
          </v-select>

            <v-autocomplete
                v-if="formData.regionId"
                v-model="formData.uikId"
                label="Номер УИК"
                placeholder="Начните вводить номер и выберите вариант из списка"
                :items="availableUikList"
                clearable
                :loading="loading"
                :rules="[v => !!v || 'Поле обязательно']"
                @input="fetchAvailableUikList"
            >
              <template v-slot:label>
              <span>Номер УИК
                <span v-if="!formData.uikId" class="text-red" style="margin-left: 4px;">
                  *
                </span>
              </span>
              </template>
              <template v-slot:no-data>
                <p style="padding-left: 5px;">Начните вводить номер и выберите вариант из списка</p>
              </template>
            </v-autocomplete>
        </v-col>

        <!-- Информационное сообщение или сообщение о нарушении -->
        <v-col cols="12" md="4">
          <h4 class="font-weight-bold mb-4">Информационное сообщение или сообщение о нарушении</h4>

          <v-radio-group
              v-model="formData.messageType"
              :rules="[v => !!v || 'Выберите тип сообщения']"
              required
          >
            <v-radio
                color="blue"
                label="Информационное сообщение (нарушений нет)"
                value="information">
            </v-radio>
            <v-radio
                color="blue"
                label="Сигнал о нарушении"
                value="violation"
            >
            </v-radio>
          </v-radio-group>

          <v-textarea
              v-model="formData.message"
              label="Сообщение"
              color="blue"
              auto-grow
              class="mt-4"
              outlined
              rows="4"
              dense
              required
              :rules="[v => !!v || 'Поле обязательно']">
            <template v-slot:label>
              <span>Сообщение
                <span v-if="!formData.message" class="text-red" style="margin-left: 4px;">
                  *
                </span>
              </span>
            </template>
          </v-textarea>

          <v-file-input
              v-model="formData.files"
              label="Выберите файлы или перетащите их в эту область"
              color="blue"
              outlined
              small-chips
              multiple
              class="mt-4"
              prepend-icon="mdi-paperclip">
          </v-file-input>
        </v-col>


      </v-row>

      <!-- Нарушения до дня голосования -->
      <v-row class="mt-2">
        <!-- Нарушения до дня голосования -->
        <v-col cols="12" md="3" class="align-start">
          <h4 class="font-weight-bold mb-4">Нарушения до дня голосования</h4>
          <v-checkbox v-model="formData.violations" value="signatureCollection" label="Нарушения при сборе подписей" color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="agitationRules" label="Нарушение правил печатной и наружной агитации" color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="mediaAgitation" label="Нарушение правил агитации в СМИ, кроме админресурса" color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="voterBribery" label="Принуждение, подкуп избирателей" color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="earlyVotingViolations" label="Нарушения при досрочном голосовании" color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="mobileVoterViolations" label="Нарушения, связанные с «мобильным избирателем»" color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="commissionRightsViolations" label="Нарушение прав членов комиссии, наблюдателей, СМИ" color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="otherBeforeVotingViolations" label="Иные нарушения до дня голосования" color="info" dense></v-checkbox>
        </v-col>

        <!-- Нарушения в день голосования -->
        <v-col cols="12" md="3" class="align-start">
          <h4 class="font-weight-bold mb-4">Нарушения в день голосования</h4>
          <v-checkbox v-model="formData.violations" value="pollingPlaceDesign" label="Нарушение в оформлении участка" color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="preVotingViolations" label="Нарушения перед началом голосования: при опечатывании ящиков, хранении и раздаче бюллетеней, неоглашение данных и пр." color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="observerRightsViolations" label="Нарушение прав наблюдателей, членов комиссии, представителей СМИ: ограничение перемещения, запрет фотосъемки и пр." color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="votingViolations" label="Нарушения при голосовании: вброс, голосование за других лиц, незаконное включение в список избирателей и пр." color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="homeVotingViolations" label="Нарушения при голосовании «на дому»" color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="voterListViolations" label="Невключение в список избирателей, непредоставление права голоса" color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="voterCoercion" label="Принуждение избирателей, подвоз или нарушение тайны голосования" color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="illegalAgitation" label="Незаконная агитация, лотереи, подкуп" color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="complaintHandlingViolations" label="Непринятие или нерассмотрение жалоб" color="info" dense></v-checkbox>
          <v-checkbox v-model="formData.violations" value="otherVotingDayViolations" label="Иные нарушения в день голосования" color="info" dense></v-checkbox>
        </v-col>

        <!-- Нарушения при подсчете голосов -->
        <v-col cols="12" md="3">
          <h4 class="font-weight-bold mb-4">Нарушения при подсчете голосов</h4>
          <v-checkbox v-model="formData.violations" value="voteCountingViolations" label="Нарушение при подсчете голосов: совмещение этапов, неоглашение и незанесение данных в увеличенную форму протокола, негласная сортировка и подсчет и пр." color="info"></v-checkbox>
          <v-checkbox v-model="formData.violations" value="resultDistortion" label="Искажения результатов: вброс, перекладывание из пачки в пачку, переписывание результатов и пр." color="info"></v-checkbox>
          <v-checkbox v-model="formData.violations" value="protocolViolations" label="Негласное составление протокола, неправильная упаковка документов, незаверение копии протокола и пр." color="info"></v-checkbox>
          <v-checkbox v-model="formData.violations" value="higherCommissionsViolations" label="Нарушения при обработке и установлении итогов в вышестоящих комиссиях" color="info"></v-checkbox>
          <v-checkbox v-model="formData.violations" value="otherCountingViolations" label="Иные нарушения при подсчете и установлении итогов" color="info"></v-checkbox>
        </v-col>

        <!-- Злоупотребления административным ресурсом -->
        <v-col cols="12" md="3">
          <h4 class="font-weight-bold mb-4">Злоупотребления административным ресурсом</h4>
          <v-checkbox v-model="formData.violations" value="registrationRefusals" label="Неправомерные отказы при выдвижении и регистрации кандидатов" color="info"></v-checkbox>
          <v-checkbox v-model="formData.violations" value="mediaUsageWithoutPayment" label="Использование государственных и муниципальных СМИ для агитации без оплаты из избирательного фонда" color="info"></v-checkbox>
          <v-checkbox v-model="formData.violations" value="meetingAdvantages" label="Создание преимуществ для встреч с избирателями" color="info"></v-checkbox>
          <v-checkbox v-model="formData.violations" value="pressureOnVoters" label="Давление начальства на избирателей" color="info"></v-checkbox>
          <v-checkbox v-model="formData.violations" value="lawEnforcementPressure" label="Воздействие правоохранительных органов" color="info"></v-checkbox>
          <v-checkbox v-model="formData.violations" value="otherAdminResourceAbuse" label="Иные виды использования административного ресурса в целях достижения определенного результата на выборах" color="info"></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="my-custom-button">
          <v-btn class="mb-4" rounded color="info" @click="submitForm">
            Отправить сообщение
          </v-btn>
        </v-col>
      </v-row>

      <v-dialog v-model="dialogVisible" max-width="500">
        <v-card>
          <v-card-title class="headline">Сообщение отправлено!</v-card-title>
          <v-card-text>
            Ваше сообщение было успешно отправлено. Что вы хотите сделать дальше?
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="sendAnotherMessage">Отправить еще одно</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-form>
  </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import UserHeader from '@/components/UserHeader.vue';

export default {
  components: {
    UserHeader,
  },
  data() {
    return {
      emailPattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z\u00C0-\uFFFF]{2,}$/,
      availableUikList: [],
      formValid: false,
      loading: false,
      dialogVisible: false,
      regionsLoaded: false,
      formData: {
        name: '',
        email: '',
        showName: false,
        regionId: null,
        uikId: '',
        messageType: '',
        message: '',
        files: [],
        agreeWithTerms: false,
        violations: [],
        otherContact: ''
      },
      regions: [],
    };
  },
  computed: {
    isEmailValid() {
      return this.emailPattern.test(this.formData.email);
    }
  },
  watch: {
    'formData.violations'(newVal) {
      if (newVal.length > 0) {
        this.formData.messageType = 'violation';
      }
    }
  },
  methods: {
    fetchAvailableUikList: _.debounce(function(value) {
      if (!value) {
        this.items = [];
        return;
      }

      this.loading = true;

      axios.get(`${process.env.VUE_APP_BACKEND_API_BASE_URL}/api/availableUikList`, { params: { uikNumber: value.target.value, regionId: this.formData.regionId } })
          .then(response => {
            this.availableUikList = response.data.map(item => ({
              value: item.id,
              title: item.uikNumber
            }));
          })

          .catch(error => {
            console.error('Ошибка при получении данных:', error);
          })
          .finally(() => {
            this.loading = false;
          });
    }, 300),
    async loadRegions() {
      if (!this.regionsLoaded) {
        this.loading = true;
        try {
          const response = await axios.get(`${process.env.VUE_APP_BACKEND_API_BASE_URL}/api/regions`);
          this.regions = response.data.regions.map(region => ({
            title: region.name,
            value: region.id
          }));
          this.regionsLoaded = true;
        } catch (error) {
          console.error('Ошибка при загрузке регионов:', error);
        } finally {
          this.loading = false;
        }
      }
    },
    async submitForm() {
      const validationState = await this.$refs.form.validate();

      if (validationState.valid) {
        try {
          const formData = new FormData();
          for (const key in this.formData) {
            if (Array.isArray(this.formData[key])) {
              this.formData[key].forEach((item, index) => {
                formData.append(`${key}[${index}]`, item);
              });
            } else {
              formData.append(key, this.formData[key]);
            }
          }

          const response = await axios.post(`${process.env.VUE_APP_BACKEND_API_BASE_URL}/site/addMessage`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          console.log('Сообщение отправлено:', response.data);
          this.dialogVisible = true;

          // Очистка формы после успешной отправки
          //this.$refs.form.reset();
          //this.formData.violations = [];
        } catch (error) {
          console.error('Ошибка при отправке сообщения:', error);
        }
      } else {
        this.scrollToFirstInvalidField();
        console.log('Форма невалидна');
      }
    },
    scrollToFirstInvalidField() {
      const firstInvalidField = this.$el.querySelector('.v-input--error');

      if (firstInvalidField) {
        firstInvalidField.scrollIntoView({ behavior: 'smooth' });
        firstInvalidField.focus();
      }
    },
    sendAnotherMessage() {
      this.dialogVisible = false;
      this.$refs.form.reset();
      this.formData.violations = [];
    },
  },
};
</script>
