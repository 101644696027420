<template>
  <v-slide-y-transition>
    <div class="filter-list custom-filter__text">
      <v-list dense>
        <div>

          <div v-if="isMobile">
            <v-row>
              <v-col class="custom-font__filter">
                <v-list-item>
                  <v-list-item-content>
                    Всего сообщений: <a class="text-decoration-none text-primary" href="#">
                    {{ allMessage }}</a>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    {{ getStatusText('INFORMATION') }}
                    <v-btn @click="emitFilterEvent('INFORMATION')" size="x-small" class="pa-0 text-caption"
                           :color="getStatusColor('INFORMATION')" rounded
                           variant="tonal">
                      {{ infoMessage }}
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    {{ getStatusText('VIOLATION') }}
                    <v-btn @click="emitFilterEvent('VIOLATION')" size="x-small" class="pa-0 text-caption"
                           :color="getStatusColor('VIOLATION')" rounded
                           variant="tonal">
                      {{ violation }}
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    {{ getStatusText('FAKE') }}
                    <v-btn @click="emitFilterEvent('FAKE')" size="x-small" class=" pa-0 text-caption"
                           :color="getStatusColor('FAKE')" rounded
                           variant="tonal">
                      {{ fake }}
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    {{ getStatusText('PROCESSING') }}
                    <v-btn @click="emitFilterEvent('PROCESSING')" size="x-small" class="pa-0 text-caption"
                           :color="getStatusColor('PROCESSING')" rounded
                           variant="tonal">
                      {{ inProcessing }}
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    {{ getStatusText('RESOLVED') }}
                    <v-btn @click="emitFilterEvent('RESOLVED')" size="x-small" class="pa-0 text-caption "
                           :color="getStatusColor('RESOLVED')" rounded
                           variant="tonal">
                      {{ problemSolved }}
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    {{ getStatusText('CANCEL') }}
                    <v-btn @click="emitFilterEvent('CANCEL')" size="x-small"
                           class="pa-0 text-caption custom-chip__rejected" rounded variant="tonal">
                      {{ rejectedMessage }}
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </div>

          <div v-else>
            <v-row>
              <v-list-item>
                Всего сообщений: <a class="text-decoration-none text-primary" href="#">
                {{ allMessage }}</a>
              </v-list-item>
              <v-list-item>
                {{ getStatusText('INFORMATION') }}
                <v-btn @click="emitFilterEvent('INFORMATION')" size="x-small" class="pa-0 text-caption"
                       :color="getStatusColor('INFORMATION')" rounded
                       variant="tonal">
                  {{ infoMessage }}
                </v-btn>
              </v-list-item>
              <v-list-item>
                {{ getStatusText('VIOLATION') }}
                <v-btn @click="emitFilterEvent('VIOLATION')" size="x-small" class="pa-0 text-caption"
                       :color="getStatusColor('VIOLATION')" rounded
                       variant="tonal">
                  {{ violation }}
                </v-btn>
              </v-list-item>
              <v-list-item>
                {{ getStatusText('FAKE') }}
                <v-btn @click="emitFilterEvent('FAKE')" size="x-small" class=" pa-0 text-caption"
                       :color="getStatusColor('FAKE')" rounded
                       variant="tonal">
                  {{ fake }}
                </v-btn>
              </v-list-item>
              <template v-if="isAuthenticated()">
                <v-list-item>
                  {{ getStatusText('PROCESSING') }}
                  <v-btn @click="emitFilterEvent('PROCESSING')" size="x-small" class="pa-0 text-caption"
                         :color="getStatusColor('PROCESSING')" rounded
                         variant="tonal">
                    {{ inProcessing }}
                  </v-btn>
                </v-list-item>
              </template>
              <v-list-item>
                {{ getStatusText('RESOLVED') }}
                <v-btn @click="emitFilterEvent('RESOLVED')" size="x-small" class="pa-0 text-caption "
                       :color="getStatusColor('RESOLVED')" rounded
                       variant="tonal">
                  {{ problemSolved }}
                </v-btn>
              </v-list-item>
              <template v-if="isAuthenticated()">
                <v-list-item>
                  {{ getStatusText('CANCEL') }}
                  <v-btn @click="emitFilterEvent('CANCEL')" size="x-small"
                         class="pa-0 text-caption custom-chip__rejected" rounded variant="tonal">
                    {{ rejectedMessage }}
                  </v-btn>
                </v-list-item>
              </template>

                <template v-if="urlToMap">
                  <v-list-item>
                  <a :href="urlToMap" target="_blank">
                    <v-btn size="x-small" class="text-decoration-none text-primary" rounded variant="tonal">
                      Показать на карте
                    </v-btn>
                  </a>
                  </v-list-item>
                </template>

            </v-row>
          </div>
        </div>

      </v-list>

    </div>

  </v-slide-y-transition>

</template>

<script>
import {statusMixin} from "@/mixins/statusMixin";
import {useAuth} from "@/composables/useAuth";

export default {
  setup() {
    const {isAuthenticated, user} = useAuth();

    return {
      isAuthenticated,
      user,
    };
  },
  mixins: [statusMixin],
  data() {
    return {
      isMobile: false,
      show: false,
      allMessage: "0",
      infoMessage: "0",
      violation: "0",
      fake: "0",
      inProcessing: "0",
      problemSolved: "0",
      rejectedMessage: "0",
      urlToMap: "",
    };
  },
  methods: {
    emitFilterEvent(status) {
      this.$emit('filterByStatus', status);
    },
    toggle() {
      this.show = !this.show;
    },
    async fetchMessageCounts() {
      try {
        const savedFilters = localStorage.getItem('currentFilters');
        const filters = savedFilters ? JSON.parse(savedFilters) : {};

        let url = `${process.env.VUE_APP_BACKEND_API_BASE_URL}/api/messages/count`;
        if (filters.region && filters.region.value) {
          url += `?region=${encodeURIComponent(filters.region.value)}`;
        }

        const response = await fetch(url, {
          method: 'GET', // Метод GET подразумевает передачу параметров в URL
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) throw new Error('Network response was not ok');
        const counts = await response.json();

        this.allMessage = counts.totalMessages ? counts.totalMessages.toString() : "0";
        this.infoMessage = counts.statusesCount && counts.statusesCount.INFORMATION ? counts.statusesCount.INFORMATION.toString() : "0";
        this.violation = counts.statusesCount && counts.statusesCount.VIOLATION ? counts.statusesCount.VIOLATION.toString() : "0";
        this.fake = counts.statusesCount && counts.statusesCount.FAKE ? counts.statusesCount.FAKE.toString() : "0";
        this.inProcessing = counts.statusesCount && counts.statusesCount.PROCESSING ? counts.statusesCount.PROCESSING.toString() : "0";
        this.problemSolved = counts.statusesCount && counts.statusesCount.RESOLVED ? counts.statusesCount.RESOLVED.toString() : "0";
        this.rejectedMessage = counts.statusesCount && counts.statusesCount.CANCEL ? counts.statusesCount.CANCEL.toString() : "0";
        this.urlToMap = counts.urlToMap ?? '';

      } catch (error) {
        console.error('Failed to fetch message counts:', error);
      }
    },
    updateWindowWidth() {
      this.isMobile = window.innerWidth <= 600;
    }
  },
  mounted() {
    this.updateWindowWidth();
    this.fetchMessageCounts();
    window.addEventListener('resize', this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

}

</script>

<style scoped>
.filter-list {
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  /* Отступ, чтобы не прилипало к кнопке */
}

.v-list {
  overflow: hidden !important;
}

:deep(.v-btn__underlay) {
  opacity: 1;
}

:deep(.v-btn__content) {
  z-index: 1;
  color: white;
  font-size: clamp(4px, 2vw, 10px);
}

.custom-chip__message {
  color: #56b07e;
}

.custom-chip__violation {
  color: #EB4954;
}

.custom-chip__fake {
  color: #707070;
}

.custom-chip__processing {
  color: #A7A7A7;
}

.custom-chip__problem {
  color: #8be0b9;
}

.custom-chip__rejected {
  color: rgb(181, 181, 57);
}

.custom-filter__text {
  font-size: clamp(4px, 2vw, 10px);
}
.custom-font__filter {
  font-size: 18px;
}

@media (min-width: 1920px) and (max-width: 3072px) {
  :deep(.v-btn__content) {
    font-size: clamp(4px, 2vw, 15px);
  }

  .custom-filter__text {
    font-size: clamp(4px, 2vw, 15px);
  }

  :deep(.v-btn--size-x-small) {
    --v-btn-size: clamp(2px, 2vw, 15px) !important;
    --v-btn-height: 25px !important;
    min-width: 75px !important;
  }
}
</style>