/* global ym */

import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '@/views/MainPage.vue';
import CustomeAuthorization from '@/views/CustomAuthorization.vue';
import CustomerMessage from '@/views/CustomerMessage.vue';
import CustomSendMessage from '@/views/CustomSendMessage.vue';
import CustomAgreement from '@/views/CustomAgreement.vue';

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage,
    beforeEnter: (to, from, next) => {
      if (!to.query.page) {
        next({
          ...to,
          query: {
            ...to.query,
            page: 1
          }
        });
      } else {
        next();
      }
    }
  },
  {
    path: '/message/:messageID',
    name: 'CustomerMessage',
    component: CustomerMessage,
    props: true
  },
  {
    path: '/authorization',
    name: 'CustomAuthorization',
    component: CustomeAuthorization
  },
  {
    path: '/sendMessage',
    name: 'CustomSendMessage',
    component: CustomSendMessage
  },
  {
    path: '/customAgreement',
    name: 'CustomAgreement',
    component: CustomAgreement
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Добавление отслеживания переходов для Яндекс.Метрики
router.afterEach((to, from) => {
  // Убедитесь, что ym функция уже доступна
  if (typeof ym !== 'undefined') {
    ym(96783538, 'hit', to.fullPath, {
      referer: from.fullPath
    });
  }
});

export default router;
