<template>
    <div>
        <v-container>
            <v-btn class="font-weight-bold mt-5" color="info" @click="dialog = true"
                append-icon="mdi mdi-chevron-down" variant="text">
                Все фильтры
            </v-btn>
            <!-- Диалоговое окно с фильтрами -->
            <v-dialog class="text-filter__all" v-model="dialog" max-width="1500px">
                <v-card>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-card-title>
                                        <span class="headline text-info">Все фильтры</span>
                                    </v-card-title>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="3">
                                    <v-select
                                        v-model="filters.region" :items="regions"
                                        item-value="code"
                                        item-text="name"
                                        label="Регион"
                                        clearable
                                        filterable
                                        return-object
                                        :disabled="isRegionDisabled"></v-select>
                                </v-col>
                              <v-col class="d-flex" cols="12" md="6" sm="12">
                                <span class="custom-uik pa-0 ma-0 mt-4">Номер УИК</span>
                                <v-text-field v-model="filters.uikNumber" class="ml-5"></v-text-field>
                                <span class="custom-uik pa-0 ma-0 mt-4 ml-3">или диапазон</span>
                                <v-text-field v-model="filters.uikNumberFrom" class="ml-5" label="От"></v-text-field>
                                <v-text-field v-model="filters.uikNumberTo" class="ml-5" label="До"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                            </v-row>

                            <v-row v-if="isMobile">
                                <v-col class="ml-5 pa-0 text-filter__all">
                                  <span>Характер сообщения</span>
                                    <v-list lines="one">
                                        <v-checkbox v-model="filters.INFORMATION" class="custom-check__box ma-0 pa-0"
                                            v-if="getSpecificStatus('INFORMATION')"
                                            :label="getSpecificStatus('INFORMATION').text"></v-checkbox>
                                        <v-checkbox v-model="filters.VIOLATION" class="custom-check__box pa-0 ma-0"
                                            v-if="getSpecificStatus('VIOLATION')"
                                            :label="getSpecificStatus('VIOLATION').text"></v-checkbox>

                                        <div>
                                            <v-checkbox v-model="filters.FAKE" class="custom-check__box ma-0 pa-0"
                                                v-if="getSpecificStatus('FAKE')"
                                                :label="getSpecificStatus('FAKE').text"></v-checkbox>
                                            <v-checkbox v-model="filters.PROCESSING"
                                                class="custom-check__box ma-0 pa-0 mt-1"
                                                v-if="getSpecificStatus('PROCESSING')"
                                                :label="getSpecificStatus('PROCESSING').text"></v-checkbox>
                                        </div>

                                        <div>
                                            <v-checkbox v-model="filters.RESOLVED" class="custom-check__box ma-0 pa-0"
                                                v-if="getSpecificStatus('RESOLVED')"
                                                :label="getSpecificStatus('RESOLVED').text"></v-checkbox>
                                            <v-checkbox v-model="filters.CANCEL"
                                                class="custom-check__box ma-0 pa-0 mt-1"
                                                v-if="getSpecificStatus('CANCEL')"
                                                :label="getSpecificStatus('CANCEL').text"></v-checkbox>
                                        </div>
                                    </v-list>
                                </v-col>
                            </v-row>

                            <v-row v-else>
                                <v-col class="ml-5 pa-0 text-filter__all">
                                    <span>Характер сообщения</span>
                                    <v-checkbox v-model="filters.INFORMATION" class="custom-check__box ma-0 mt-5 pa-0"
                                        v-if="getSpecificStatus('INFORMATION')"
                                        :label="getSpecificStatus('INFORMATION').text"></v-checkbox>
                                    <v-checkbox v-model="filters.VIOLATION" class=" custom-check__box pa-0 ma-0"
                                        v-if="getSpecificStatus('VIOLATION')"
                                        :label="getSpecificStatus('VIOLATION').text"></v-checkbox>
                                </v-col>
                                <v-col>
                                    <div class="mt-9">
                                        <v-checkbox v-model="filters.FAKE" class="custom-check__box ma-0 pa-0"
                                            v-if="getSpecificStatus('FAKE')"
                                            :label="getSpecificStatus('FAKE').text"></v-checkbox>
                                        <v-checkbox v-model="filters.PROCESSING"
                                            class="custom-check__box ma-0 pa-0 mt-1"
                                            v-if="getSpecificStatus('PROCESSING')"
                                            :label="getSpecificStatus('PROCESSING').text"></v-checkbox>
                                    </div>
                                </v-col>
                                <v-col>
                                    <div class="mt-9">
                                        <v-checkbox v-model="filters.RESOLVED" class="custom-check__box ma-0 pa-0"
                                            v-if="getSpecificStatus('RESOLVED')"
                                            :label="getSpecificStatus('RESOLVED').text"></v-checkbox>
                                        <v-checkbox v-model="filters.CANCEL" class="custom-check__box ma-0 pa-0 mt-1"
                                            v-if="getSpecificStatus('CANCEL')"
                                            :label="getSpecificStatus('CANCEL').text"></v-checkbox>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12 mx-1">
                                    <span class="mx-2">Дополнительные критерии</span>
                                    <div class="d-flex flex-column flex-md-row mt-3 mx-1">
                                        <v-checkbox v-model="filters.hasFiles" label="Только с файлами"
                                            class="mb-2 mb-md-0 mr-md-4"></v-checkbox>
                                        <v-checkbox v-model="filters.onlyWithViolations" label="Сигнал о нарушении"></v-checkbox>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="custom-btn">
                                    <v-btn class="mb-2" color="info" rounded text @click="applyFilters">Применить</v-btn>
                                    <v-btn class="ml-5" rounded color="grey" text @click="dialog = false">Отмена</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue';
import { statusMixin } from "@/mixins/statusMixin";

export default {
    mixins: [statusMixin],

    setup(props, { emit }) {
        const dialog = ref(false);
        const currentUserRegionTest = ref(localStorage.getItem('regionIso') || null);

        const currentUserRegion = currentUserRegionTest.value;

        const regions = ref([
            { title: "Выберите регион", value: null },
            { title: "Республика Адыгея", value: "01" },
            { title: "Республика Алтай", value: "02" },
            { title: "Республика Башкортостан", value: "03" },
            { title: "Республика Бурятия", value: "04" },
            { title: "Республика Дагестан", value: "05" },
            { title: "Республика Ингушетия", value: "06" },
            { title: "Кабардино-Балкарская Республика", value: "07" },
            { title: "Республика Калмыкия", value: "08" },
            { title: "Карачаево-Черкесская Республика", value: "09" },
            { title: "Республика Карелия", value: "10" },
            { title: "Республика Коми", value: "11" },
            { title: "Республика Крым", value: "12" },
            { title: "Республика Марий Эл", value: "13" },
            { title: "Республика Мордовия", value: "14" },
            { title: "Республика Саха (Якутия)", value: "15" },
            { title: "Республика Северная Осетия - Алания", value: "16" },
            { title: "Республика Татарстан", value: "17" },
            { title: "Республика Тыва", value: "18" },
            { title: "Удмуртская Республика", value: "19" },
            { title: "Республика Хакасия", value: "20" },
            { title: "Чеченская Республика", value: "21" },
            { title: "Чувашская Республика", value: "22" },
            { title: "Алтайский край", value: "23" },
            { title: "Забайкальский край", value: "24" },
            { title: "Камчатский край", value: "25" },
            { title: "Краснодарский край", value: "26" },
            { title: "Красноярский край", value: "27" },
            { title: "Пермский край", value: "28" },
            { title: "Приморский край", value: "29" },
            { title: "Ставропольский край", value: "30" },
            { title: "Хабаровский край", value: "31" },
            { title: "Амурская область", value: "32" },
            { title: "Архангельская область", value: "33" },
            { title: "Астраханская область", value: "34" },
            { title: "Белгородская область", value: "35" },
            { title: "Брянская область", value: "36" },
            { title: "Владимирская область", value: "37" },
            { title: "Волгоградская область", value: "38" },
            { title: "Вологодская область", value: "39" },
            { title: "Воронежская область", value: "40" },
            { title: "Ивановская область", value: "41" },
            { title: "Иркутская область", value: "42" },
            { title: "Калининградская область", value: "43" },
            { title: "Калужская область", value: "44" },
            { title: "Кемеровская область", value: "45" },
            { title: "Кировская область", value: "46" },
            { title: "Костромская область", value: "47" },
            { title: "Курганская область", value: "48" },
            { title: "Курская область", value: "49" },
            { title: "Ленинградская область", value: "50" },
            { title: "Липецкая область", value: "51" },
            { title: "Магаданская область", value: "52" },
            { title: "Московская область", value: "53" },
            { title: "Мурманская область", value: "54" },
            { title: "Нижегородская область", value: "55" },
            { title: "Новгородская область", value: "56" },
            { title: "Новосибирская область", value: "57" },
            { title: "Омская область", value: "58" },
            { title: "Оренбургская область", value: "59" },
            { title: "Орловская область", value: "60" },
            { title: "Пензенская область", value: "61" },
            { title: "Псковская область", value: "62" },
            { title: "Ростовская область", value: "63" },
            { title: "Рязанская область", value: "64" },
            { title: "Самарская область", value: "65" },
            { title: "Саратовская область", value: "66" },
            { title: "Сахалинская область", value: "67" },
            { title: "Свердловская область", value: "68" },
            { title: "Смоленская область", value: "69" },
            { title: "Тамбовская область", value: "70" },
            { title: "Тверская область", value: "71" },
            { title: "Томская область", value: "72" },
            { title: "Тульская область", value: "73" },
            { title: "Тюменская область", value: "74" },
            { title: "Ульяновская область", value: "75" },
            { title: "Челябинская область", value: "76" },
            { title: "Ярославская область", value: "77" },
            { title: "Москва", value: "78" },
            { title: "Санкт-Петербург", value: "79" },
            { title: "Севастополь", value: "80" },
            { title: "Еврейская автономная область", value: "81" },
            { title: "Ненецкий автономный округ", value: "82" },
            { title: "Ханты-Мансийский автономный округ - Югра", value: "83" },
            { title: "Чукотский автономный округ", value: "84" },
            { title: "Ямало-Ненецкий автономный округ", value: "85" },
        ]);

        const defaultRegion = regions.value.find(region => region.value === currentUserRegion);

        const filters = reactive({
            region: defaultRegion,
            uikNumber: '',
            uikNumberFrom: null,
            uikNumberTo: null,
            INFORMATION: false,
            VIOLATION: false,
            FAKE: false,
            RESOLVED: false,
            CANCEL: false,
            PROCESSING: false,
            hasFiles: false,
            onlyWithViolations: false,
        });
        const currentFilters = ref({});

        watch(() => filters.uikNumber, (newValue) => {
            if (newValue) {
                filters.uikNumberFrom = null;
                filters.uikNumberTo = null;
            }
        });

        watch([() => filters.uikNumberFrom, () => filters.uikNumberTo], ([newFrom, newTo]) => {
            if (newFrom || newTo) {
                filters.uikNumber = '';
            }
        });

      function applyFilters() {
            const currentFilters = {
                useFilter: true,
                region: filters.region,
                uikNumber: filters.uikNumber,
                uikRange: {
                    from: filters.uikNumberFrom,
                    to: filters.uikNumberTo,
                },
                status: {
                    INFORMATION: filters.INFORMATION,
                    VIOLATION: filters.VIOLATION,
                    FAKE: filters.FAKE,
                    PROCESSING: filters.PROCESSING,
                    RESOLVED: filters.RESOLVED,
                    CANCEL: filters.CANCEL,
                },
                hasFiles: filters.hasFiles,
                onlyWithViolations: filters.onlyWithViolations,
            };

            localStorage.setItem('currentFilters', JSON.stringify(currentFilters));
            emit('apply-filters', currentFilters);

            dialog.value = false;
        }

        const cfr = ref(localStorage.getItem('cfr') || null);

        const isRegionDisabled = computed(() => {
            return cfr.value === 'false';
        });

        return {
            dialog,
            filters,
            regions,
            applyFilters,
            currentFilters,
            isRegionDisabled,
        };

    },

    data() {
        return {
            isMobile: false,
        };
    },
    methods: {
        updateWindowWidth() {
            this.isMobile = window.innerWidth <= 672;
        }
    },
    mounted() {
        this.updateWindowWidth();
        window.addEventListener('resize', this.updateWindowWidth);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateWindowWidth);
    }
};
</script>

<style scoped>
.custom-uik {
    margin-left: 36%;
    font-size: clamp(2px, 2vw, 12px);
}
.custom-btn {
    display: contents;
}
:deep(.custom-check__box .v-input__details) {
  min-height: 10px !important;
}

:deep(.v-selection-control .v-overlay-container) {
  font-size: clamp(12px, 2vw, 16px);
  opacity: 0.8;
}

:deep(.v-input--density-default) {
  --v-input-control-height: 40px;
}
</style>
