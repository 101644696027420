<template>
  <div>
    <v-container>
      <!-- Заголовок -->
      <UserHeader />
      <!-- Поиск и фильтры -->
      <UserSearch></UserSearch>
      <v-row>
        <v-col>
          <v-btn class="custom-btn" href="/sendMessage">
            Отправить сообщения
          </v-btn>
        </v-col>
      </v-row>
      <template v-if="isAuthenticated()">
        <UserAllFilter @apply-filters="handleApplyFilters" />
        <v-row align="center">
          <v-col v-if="Object.keys(selectedFilters).length > 0" class="d-flex align-center" cols="auto">
            <h4 class="mb-0 mr-4">Активные фильтры:</h4>
          </v-col>
          <v-col>
            <v-chip
                v-for="(filter, key) in selectedFilters"
                :key="key"
                color="blue"
                label
                outlined
                class="ma-2"
            >
              {{ filter }}
            </v-chip>
          </v-col>
        </v-row>
        <UserFilter @filterByStatus="handleFilterByStatus" ref="userFilterComponent"/>
      </template>
      <!-- Список сообщений -->
    </v-container>

    <v-container>
      <UserMessage ref="userMessagesComponent" />
    </v-container>
  </div>
</template>

<script>
import { useAuth } from '@/composables/useAuth';
import UserAllFilter from '@/components/UserAllFilter.vue';
import UserFilter from '@/components/UserFilter.vue';
import UserSearch from '@/components/UserSearch.vue';
import UserMessage from '@/components/UserMessage.vue';
import UserHeader from '@/components/UserHeader.vue';
import { ref, reactive, computed } from 'vue';

export default {
  components: {
    UserFilter,
    UserSearch,
    UserMessage,
    UserAllFilter,
    UserHeader,
  },
  setup() {
    const { isAuthenticated, user, logout } = useAuth();
    const userMessagesComponent = ref(null);
    const userFilterComponent = ref(null);
    const filters = reactive({
      useFilter: false,
      uikNumber: null,
      uikRange: {
        from: null,
        to: null,
      },
      status: {
        INFORMATION: false,
        VIOLATION: false,
        FAKE: false,
        PROCESSING: false,
        RESOLVED: false,
        CANCEL: false,
      },
      hasFiles: false,
      onlyWithViolations: false,
    });

    const selectedFilters = computed(() => processFilters(filters));

    function processFilters(filters) {
      const selected = {};

      if (filters.uikNumber) {
        selected['uikNumber'] = `УИК: ${filters.uikNumber}`;
      }

      if (filters.uikRange && (filters.uikRange.from || filters.uikRange.to)) {
        selected['uikRange'] = `Диапазон УИК от ${filters.uikRange.from || 'null'} до ${filters.uikRange.to || 'null'} `;
      }

      const statusNames = {
        INFORMATION: 'Информационное',
        VIOLATION: 'Нарушение',
        FAKE: 'Фейк',
        PROCESSING: 'В обработке',
        RESOLVED: 'Решено',
        CANCEL: 'Отклонено'
      };

      const activeStatuses = Object.keys(filters.status || {}).filter(key => filters.status[key]);

      if (activeStatuses.length > 0) {
        selected['status'] = `По статусам: [ ${activeStatuses.map(status => statusNames[status]).join(', ')} ]`;
      }

      if (filters.hasFiles) {
        selected['hasFiles'] = 'Только с файлами';
      }

      if (filters.onlyWithViolations) {
        selected['onlyWithViolations'] = 'Только сигналы о нарушении';
      }

      return selected;
    }

    function removeFilter(key) {
      switch (key) {
        case 'useFilter':
        case 'hasFiles':
        case 'onlyWithViolations':
          filters[key] = false;
          break;

        case 'uikNumber':
          filters.uikNumber = null;
          break;

        case 'uikRange':
          filters.uikRange.from = null;
          filters.uikRange.to = null;
          break;

        case 'status':
          Object.keys(filters.status).forEach(statusKey => {
            filters.status[statusKey] = false;
          });
          break;

        default:
          break;
      }
    }

    function handleFilterByStatus(status) {
      console.log('MainPage -> handleFilterByStatus');
      if (userMessagesComponent.value) {
        userMessagesComponent.value.fetchMessages(status, null, 1);
      }
    }

    function handleApplyFilters(newFilters) {
      console.log('MainPage -> handleApplyFilters');
      Object.assign(filters, newFilters);
      if (userMessagesComponent.value) {
        userMessagesComponent.value.fetchMessages(null, filters, 1);
        userFilterComponent.value.fetchMessageCounts();
      }
    }

    return {
      handleFilterByStatus,
      handleApplyFilters,
      userFilterComponent,
      filters,
      selectedFilters,
      userMessagesComponent,
      isAuthenticated,
      user,
      logout,
      removeFilter, // Метод удаления фильтра
    };
  }
};
</script>


<style scoped>
.custom-btn {
  margin-left: 15px;
}

:deep(.header-btn .v-btn__content) {
  font-size: clamp(4px, 2vw, 15px);
}
</style>
